import React from 'react';
import styled from 'styled-components';

import { PrimaryHeading } from '../reusables/textElements';
import { breakpoints } from '../../lib/constants';

const Container = styled.div({
  marginBottom: 100,
  marginTop: 50,
  display: 'flex',
  [`@media (max-width: ${breakpoints.oneColumn}px)`]: {
    flexDirection: 'column',
  },
});
const Description = styled.div({
  marginLeft: 'auto',
  maxWidth: 435,
  fontSize: 17,
  [`@media (max-width: ${breakpoints.oneColumn}px)`]: {
    marginTop: 10,
    maxWidth: '100%',
  },
});

function CasesIntro() {
  return (
    <Container>
      <PrimaryHeading css={{ maxWidth: 550, marginRight: 20 }}>
        Såhär gör vi skillnad
      </PrimaryHeading>
      <Description>
        Vi skapar hållbar beteendeförändring inom både näringsliv och offentlig
        sektor som samhällsbyggnad-, transport-, retail, vård, matproduktion och
        arbetsmiljö. Genom en tvärdisciplinär arbetsprocess kombinerar vi
        metoder och insikter från{' '}
        <b>tjänstedesign, psykologi och beteendeekonomi</b> med användaren i
        fokus för att skapa lönsamma lösningar för positiv förändring för både{' '}
        <b>person, plånbok och planet</b>.
      </Description>
    </Container>
  );
}

export default CasesIntro;

import styled from 'styled-components';

import { breakpoints, colors } from '../../lib/constants';

export const Clients = styled.div({
  textAlign: 'center',
  position: 'relative',
  padding: '0 30px',
  display: 'grid',
  gridTemplateColumns: '1fr 1fr 1fr',
  maxWidth: 900,
  columnGap: 50,
  margin: '0 auto',
  [`@media (max-width: 600px)`]: {
    gridTemplateColumns: '1fr 1fr',
  },
  [`@media (max-width: 450px)`]: {
    gridTemplateColumns: '1fr',
  },
});

export const ClientsHeading = styled.h3({
  textTransform: 'uppercase',
  fontSize: 48,
  margin: '20px 0 0',
  textAlign: 'center',
  [`@media (max-width: ${breakpoints.oneColumn}px)`]: {
    fontSize: 36,
  },
});

export const Client = styled.div({
  mixBlendMode: 'multiply',
});

import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import React from 'react';
import styled from 'styled-components';

import {
  Clients,
  ClientsHeading,
  Client,
} from '../components/case/StyledElements';
import { breakpoints, gutters } from '../lib/constants';
import AllCases from '../components/case/AllCases';
import AppChrome from '../components/AppChrome';
import CasesIntro from '../components/case/CasesIntro';
import ContentWrapper from '../components/reusables/ContentWrapper';
import Footer from '../components/Footer';
import SingleCase from '../components/case/SingleCase';
import TopNav from '../components/TopNav';
import { colors } from '../lib/constants';

const Cases = styled.div`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-gap: ${gutters.vertical}px;
  @media (max-width: ${breakpoints.oneColumn}px) {
    display: block;
  }
  *:nth-child(1) {
    grid-column: 3 / span 8;
  }
  *:nth-child(2) {
    grid-column: 1 / span 6;
  }
  *:nth-child(3) {
    grid-column: 7 / span 6;
  }
`;

function Case({ data }) {
  const allCases = data.allContentfulCase.edges;
  const featuredCases = data.allContentfulSettings.edges[0].node.featuredCases;
  const featuredIds = featuredCases.map(item => item.id);
  const allCasesButFeatured = allCases.filter(
    ({ node: { id } }) => !featuredIds.includes(id)
  );
  const clientImages =
    data.allContentfulSettings.edges[0].node.casesPageClients;
  return (
    <AppChrome
      title="Case"
      og={{
        title: 'Case',
        description: `
          Några av de projekt vi haft förmånen att driva.
        `,
        image: featuredCases[0].heroImage.sizes.src,
      }}
      footer={
        <Footer waveColor={colors.basePink} mirrored={false}>
          <ContentWrapper>
            <ClientsHeading>Vi är stolta över våra kunder</ClientsHeading>
          </ContentWrapper>
          <div css={{ position: 'relative' }}>
            <Clients>
              {clientImages.map((img, i) => (
                <Client key={i}>
                  <Img sizes={img.sizes} />
                </Client>
              ))}
            </Clients>
          </div>
        </Footer>
      }
    >
      <TopNav />
      <ContentWrapper>
        <CasesIntro />
        <Cases>
          {featuredCases.map((node, i) => {
            return <SingleCase {...node} key={i} />;
          })}
        </Cases>
        <AllCases items={allCasesButFeatured} />
      </ContentWrapper>
    </AppChrome>
  );
}

export default Case;

export const pageQuery = graphql`
  query {
    allContentfulCase(sort: { fields: [weight], order: ASC }) {
      edges {
        node {
          id
          title
          slug
          client
          introduction {
            childMarkdownRemark {
              html
            }
          }
          heroImage {
            sizes(
              maxWidth: 690
              maxHeight: 480
              background: "rgb:000000"
              resizingBehavior: FILL
            ) {
              ...GatsbyContentfulSizes_withWebp
            }
          }
        }
      }
    }
    allContentfulSettings {
      edges {
        node {
          casesPageClients {
            sizes(maxWidth: 600, maxHeight: 400, resizingBehavior: FILL) {
              ...GatsbyContentfulSizes_withWebp
            }
          }
          featuredCases {
            id
            title
            slug
            client
            heroImage {
              sizes(maxWidth: 1420, maxHeight: 1000, resizingBehavior: FILL) {
                ...GatsbyContentfulSizes_withWebp
              }
            }
          }
        }
      }
    }
  }
`;
